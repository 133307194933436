import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link as ScrollLink } from "react-scroll";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import '../style.css';
import {
  AiOutlineTable,
} from "react-icons/ai";
import {
  GoLog
} from "react-icons/go";


function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">

            <Nav.Item>
              <ScrollLink
                to="tabelle"
                spy={true}
                smooth={true}
                offset={-70} // Adjust this offset as needed
                duration={500}
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineTable style={{ marginBottom: "2px" }} /> Tabelle
              </ScrollLink>
            </Nav.Item>

            <Nav.Item>
              <ScrollLink
                to="regeln"
                spy={true}
                smooth={true}
                offset={-70} // Adjust this offset as needed
                duration={500}
                onClick={() => updateExpanded(false)}
              >
                <GoLog
                  style={{ marginBottom: "2px" }}
                />{" "}
                Regeln
              </ScrollLink>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
