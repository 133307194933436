import React, { useState, useEffect } from "react";
import dataTable from '../../assets/Forza-Liga-Table.xlsx';
import { Container, Row, Col, Tab, Tabs, Table } from "react-bootstrap";
import '../../style.css';

import * as XLSX from "xlsx";


function Tabelle({ id }) {
  const [driverData, setDriverData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [sheetDataArray, setSheetDataArray] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(dataTable)
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheetDriver = workbook.Sheets[workbook.SheetNames[2]];
      const sheetTeams = workbook.Sheets[workbook.SheetNames[3]];
      const jsonDataDriver = XLSX.utils.sheet_to_json(sheetDriver);
      const jsonDataTeams = XLSX.utils.sheet_to_json(sheetTeams);
      
      setDriverData(jsonDataDriver);
      setTeamsData(jsonDataTeams);

      const sheetDataArray = [];

      //all races
      for (let i = 4; i < workbook.SheetNames.length; i++) {
        const sheet = workbook.Sheets[workbook.SheetNames[i]];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        const rennstatus = jsonData[0].Rennstatus;
        if(rennstatus !== 'beendet') continue
        sheetDataArray.push(jsonData);
      }

      
      setSheetDataArray(sheetDataArray);

    };

    fetchData();
  }, []);

  const getColorForTeam = (team) => {
    // Define your color mappings here
    const colorMap = {
      'Scuderia Yellowbox': '#e650ef',
      'Team Capybara': '#f8980a',
      'Yellow Submarines': '#f4fc07',
      'StickdriftRacing': '#0c1ddc',
      'Breitling Racing': '#0ee3f7',
      'Pickle Pacing': '#07ac36',
      'DasTeam?': 'darkgrey',
      'TBD': 'black',
      'IT-Support': '#068d73',
      'Fabnut': 'purple',
      'Oracle Flashbang': 'lightgrey',
      'Shoemakers': 'red',
      // Add more teams and their corresponding colors as needed
    };
  
    return colorMap[team] || 'white'; // Default color if no mapping is found
  };

  return (
    <section id="tabelle">
      <Container fluid className="table-section" id="tabelle">
        <Container className="table-content">
          <Row className="table-row">
            <h1 className="heading-name">🏆ENDERGBENIS🏆</h1>
            <Tabs defaultActiveKey="drivers" id="table-tabs">
              <Tab eventKey="drivers" title="Fahrerwertung">
                <Container className="drivers-table">
                  <Col>
                    <Table hover striped bordered className="table">
                      <thead>
                        <tr>
                          <th>Rang</th>
                          <th>Fahrer</th>
                          <th>Teams</th>
                          <th>Punkte</th>
                        </tr>
                      </thead>
                      <tbody>
                        {driverData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.Position}</td>
                            <td>{item.Fahrer}</td>
                            <td>
                              <span style={{ color: getColorForTeam(item.Team), fontWeight:900 }}>{item.Team}</span>  
                            </td>
                            <td>{item.Punkte}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Container>
              </Tab>
              <Tab eventKey="teams" title="Teamwertung">
                <Container className="teams-table">
                  <Col>
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>Rang</th>
                          <th>Team</th>
                          <th>Punkte</th>
                        </tr>
                      </thead>
                      <tbody>
                        {teamsData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.Position}</td>
                            <td>
                              <span style={{ color: getColorForTeam(item.Team), fontWeight:900 }}>{item.Team}</span>  
                            </td>
                            <td>{item.Punkte}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Container>
              </Tab>
              {sheetDataArray.map((data, index) => (
                <Tab
                  key={index}
                  eventKey={`sheet-${index}`}
                  title={`Rennen ${index + 1}`}
                >
                  <Container className={`sheet-table-${index}`}>
                    <Col>
                    <h3>Rennstrecke: {data[0].Strecke}</h3>
                      <Table className="table">
                      <thead>
                        <tr>
                          <th>Position</th>
                          <th>Fahrer</th>
                          <th>Punkte</th>
                          <th>Team</th>
                        </tr>
                      </thead>
                        <tbody>
                          {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.Position}</td>
                                <td>{item.Fahrer}</td>
                                <td>{item.Punkte}</td>
                                <td>
                                  <span style={{ color: getColorForTeam(item.Team), fontWeight:900 }}>{item.Team}</span>  
                                </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Container>
                </Tab>
              ))}
            </Tabs>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Tabelle;