import { Container, Row, Col, Table, Button, Image } from "react-bootstrap";
import CountUp from 'react-countup';
import dataTable from '../../assets/Forza-Liga-Table.xlsx';
import React, { useState, useEffect } from "react";
import settingsImg from '../../assets/Settings.png'

import * as XLSX from "xlsx";

  function Regeln({ id }) {
  const [pointData, setPointData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isSettingVisible, setIsSettingVisible] = useState(false);


  const toggleTableVisibility = () => {
    setIsTableVisible(prev => !prev);
  };

  const toggleSettingVisibility = () => {
    setIsSettingVisible(prev => !prev);
  };

  
  const tableIcon = isTableVisible ? "-" : "+";
  const settingsIcon = isSettingVisible ? "-" : "+";

  useEffect(() => {

    const fetchData = async () => {
      const response = await fetch(dataTable)
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheetPoints = workbook.Sheets[workbook.SheetNames[1]];
      const jsonPoints = XLSX.utils.sheet_to_json(sheetPoints);
      
      setPointData(jsonPoints);
    };

    fetchData();
  }, []);

  return (
    <section id="tabelle">
    <Container fluid className="rules-section" id="regeln">
      <h1 className="heading-name">Das Regelwerk</h1>
        <Container className="rules-container">
        <Row>
          <Container className="countup-container">
            <Container className="countup-box">
              <CountUp className="countup-number" start={1} end={5} duration={3} enableScrollSpy />
              <span className="countup-title">Rennen</span>
            </Container>
            <div className="divider" />
            <Container className="countup-box">
              <CountUp className="countup-number" start={1} end={12} duration={3} enableScrollSpy />
              <span className="countup-title">Teams</span>
            </Container>    
            <div className="divider" />      
            <Container className="countup-box">
              <CountUp className="countup-number" start={24}  end={1} duration={3} enableScrollSpy />
              <span className="countup-title">Sieger</span>            
            </Container>
          </Container>
          </Row>
          <Row>
          <Container className="rules-text-container">
            <h1 className="rules-heading">
              Die Fische-Forza-Liga
            </h1>
            <p>
              Zweier Teams fahren um die Fahrer- und Teammeisterschaft. Jeder Platz bepunktet nach folgender Tabelle.
              <Button onClick={toggleTableVisibility} style={{ cursor: 'pointer', marginLeft: '10px' }}>{tableIcon}</Button>
            </p>
            <Container> 
            
            <Col>
            {isTableVisible && (
              <Table striped  hover bordered>
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>Punkte</th>
                  </tr>
                </thead>
                <tbody>
                  {pointData.map((item) => (
                    <tr>
                      <td>{item.Position}</td>
                      <td>{item.Punkte}</td>
                    </tr>
                  ))}
                </tbody>
            </Table>)}
            </Col>
            </Container>         
          <p>
            Sollte jemand nicht können, zählt dies ganz normal als DNF (did not finish) und die Person bekommt 0 Punkte. 
            Solltet ihr während des Rennens abbrechen, zählt jedoch eure Position, also brechen zwei Fahrer das Rennen ab, wird einer (als Beispiel) Platz 24 und derjenige welcher mehr Renndistanz absolviert hatte Platz 23 usw. 
            Solltet ihr Ersatz für einen Teammate haben, weil dieser nicht am Termin nicht kann, könnt ihr gerne mit einem "Gast" fahren. Dessen Punkte zählen dann zur Teamwertung aber der Gast fährt nicht für die Punkte des regulären Teammates. 
          </p>          
          <h1 className="rules-heading">
                Die Fahrzeuge
          </h1>
          <p>
            Forza GT bis maximal 840 Leistungsindex
            Teams dürfen verschiedene Autos nutzen, aber die Teamfarbe muss lackiert sein.
          </p>
          <h1 className="rules-heading">
          Fahrhilfen-Einstellungen
          </h1>
          Siehe Screenshot: <Button onClick={toggleSettingVisibility} style={{ cursor: 'pointer', marginLeft: '10px' }}>{settingsIcon}</Button>
          {isSettingVisible && ( <Image src={settingsImg} />)}

          <h1 className="rules-heading">
                Das Format
          </h1>
          <p>Qualifying und Rennen mit streckenabhängiger Rundenzahl </p>
        
        </Container>
      </Row>
    </Container>
    </Container>
  </section>
  );
}

export default Regeln;
