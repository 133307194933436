import Wallpaper from "../Wallpaper/Wallpaper";
import Tabelle from "../Tabelle/Tabelle";
import Regeln from "../Regeln/Regeln";


function Home() {
  return (
    <section>
    <Wallpaper />
    <Tabelle id="tabelle"/>
    <Regeln id="regeln"/>
    </section>
  );
}

export default Home;
