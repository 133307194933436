import React, { useState } from "react";
import ReactPlayer from 'react-player';
import { Container, Row, Col } from "react-bootstrap";
import { AiFillPlayCircle } from "react-icons/ai";
import '../../style.css';
import dimiClip from '../../assets/dimiClip.mp4'; // Assuming dimiClip is an mp4 file

function Wallpaper() {
  const [videoPlaying, setVideoPlaying] = useState(false);

  function handlePlayClick() {
    setVideoPlaying(true);
  }

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 className="heading-name">
                <strong className="main-name">
                  FFL - Fische Forza Liga!
                </strong>
                <p className="heading-subtitle">und Dimi</p>
              </h1>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Wallpaper;
